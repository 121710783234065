// src/pages/blog.js
import { kebabCase } from 'lodash';
import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import { GatsbyImage } from 'gatsby-plugin-image';

const BlogPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <div className="post-list">
        {posts.map(post => (
          <div key={post.node.id} className="post-list__item">
            
            < div className="post-list__thumbnail">
              <Link to={post.node.fields.slug}>
                < GatsbyImage image={post.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData} alt="thumbnail" />
              </Link>
            </div >
            
            <div className="post-list__content">
              <Link to={post.node.fields.slug}>
                <h4>{post.node.frontmatter.title}</h4> 
              </Link>
              <div className="post-list__excerpt">{post.node.excerpt}</div>
              { post.node.frontmatter.date }
              {post.node.frontmatter.tags ? (
                <div className="tags-container">
                  <ul className="taglist" >[ 
                    {post.node.frontmatter.tags.map(tag => (
                      <span key={tag + `tag`} >
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag} ,</Link>
                      </span>
                    ))} ]
                  </ul> 
                </div>
              ): null}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default BlogPage;

// Get all markdown data, in descending order by date, and grab the id, excerpt, slug, date, and title
//blog.js
export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 80)
          fields {
            slug
          }
          frontmatter {            
            title
            tags
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED,
                  width: 100,
                  height: 100,
                  placeholder: BLURRED,
                  formats: [AUTO, WEBP]
                )
                
              }
            }

          }
        }
      }
    }
  }
`;